import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import ImageGroup from './style'
const FeatureImages = ({ mainImage }) => {
  return (
    <>
      <ImageGroup>
        <div className='main'>
          <Img
            className='w-100'
            src='../../../../../assets/image/project-management/OnGrid.webp'
            alt='feature tab'
            style={{ borderRadius: '20px' }}
          />
        </div>
      </ImageGroup>
    </>
  )
}

export default FeatureImages
