import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container } from 'react-bootstrap'
import { SuperTag } from '~components'
import { Images } from '~data'
export default function InformationSection({
  colClasses,
  title,
  text,
  ...rest
}) {
  return (
    <BreadCrumb
      // style={{
      //   backgroundImage: `url(${Images.DigiTalagency.heroImg})`,
      //   height: `100%`,
      // }}
      backgroundColor='#f3f4f6'
      {...rest}
    >
      <Container>
        <Row className='row justify-content-center text-center'>
          <Col className={colClasses ? colClasses : 'col-xl-5'}>
            <BreadCrumb.Title as='h2'>Info</BreadCrumb.Title>
          </Col>
        </Row>
      </Container>
    </BreadCrumb>
  )
}
