import React from 'react'
import FeatureWidget from '../FeatureWidget'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { generatingSystemInstallationData } from '../../data/features'

const FeatureWidgetsBlock = ({ widgetData }) => {
  const { t } = useTranslation()
  const featureSectionData = generatingSystemInstallationData(t)
  return (
    <>
      <div className='feature-area--l2-widgets'>
        {featureSectionData.tabWidget1.map(
          ({ icon, iconBackground, title, text }, index) => {
            return (
              <FeatureWidget
                key={'pmftw' + index}
                // icon={icon}
                // iconBackground={iconBackground}
                title={title}
                text={text}
              />
            )
          },
        )}
      </div>
    </>
  )
}

export default FeatureWidgetsBlock
