import React from 'react'
import { PageWrapper } from '~components/Core'

import FooterSix from '~sections/Home/footerSix'
import InformationSection from '~sections/Information/titleSection'
import EbtInformation from '~sections/Information/ebtInformation'
import SystemInstallation from '~sections/Information/systemInstallation'
import IotSmartHome from '~sections/Information/iotSmartHome'
import PromoSection from '~sections/Information/promo'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     as={Link}
  //     to='/survey-request'
  //     className='d-none d-sm-flex'
  //     children='Ajukan Survei'
  //   />
  // ),
  darkLogo: false,
}

export default function Information() {
  return (
    <PageWrapper headerConfig={header}>
      <InformationSection />
      <EbtInformation />
      <SystemInstallation />
      <IotSmartHome />
      <PromoSection />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
