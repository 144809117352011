import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Integration from './style'
import { Images } from '~data'
import SectionTitle from './Components/SectionTitle'
import { useTranslation } from 'react-i18next'

const IntegrationSection = ({ ...rest }) => {
  const { t } = useTranslation()
  // <br class='d-none d-xs-block d-lg-none d-xl-block'>
  return (
    <Integration backgroundColor='#fff'>
      <Container>
        <Row className='row justify-content-center'>
          <Col className='col-xl-10'>
            <Integration.Box className='text-center'>
              <SectionTitle
                title={t('iotSmartHome', { ns: 'info' })}
                text={`${t('IOTSystemPrimary', { ns: 'info' })}
                
                <br class=' d-xs-block d-xl-block'>
                <br class=' d-xs-block d-xl-block'>
                
                ${t('IOTSystemSecondary', {
                  ns: 'info',
                })} `}
              />
            </Integration.Box>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col xs='12' className='col-xl-9 col-lg-9 col-md-9 '>
            <div className='brand-image-group brand-image-group--l2'>
              <div className='img-0'>
                <img
                  src={Images.InformasiIOT}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '-10%',
                  }}
                  alt='integration-1'
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Integration>
  )
}

export default IntegrationSection
