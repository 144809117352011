import React from 'react'
import { Tab, Nav, Container, Col, Row } from 'react-bootstrap'
import FeatureTabPenLayout from './Components/FeatureTabPenLayout'
import FeatureImages1 from './Components/FeatureImages'
import FeatureImages2 from './Components/FeatureImages2'
import FeatureImages3 from './Components/FeaturesImages3'
import Feature from './style'
import FeatureWidgetsBlock from './Components/FeatureWidgetsBlock'
import FeatureWidgetsBlock2 from './Components/FeatureWidgetsBlock2'
import FeatureWidgetsBlock3 from './Components/FeatureWidgetsBlock3'
import { generatingSystemInstallationData } from './data/features'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function SystemInstallation() {
  const { t } = useTranslation()
  const featureSectionData = generatingSystemInstallationData(t)
  return (
    <Feature background='#F47621'>
      <Container className='container'>
        <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
          <Feature.Tab>
            <Row className='justify-content-center'>
              <Col className='col-xl-7 col-lg-8 col-md-10'>
                <Nav className='nav nav-tabs' id='myTab' role='tablist'>
                  <Nav.Link eventKey='first' className='nav-link'>
                    Off-Grid
                  </Nav.Link>
                  <Nav.Link eventKey='second' className='nav-link'>
                    On-Grid
                  </Nav.Link>
                  <Nav.Link eventKey='third' className='nav-link'>
                    Hybrid
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
            <Tab.Content className='tab-content '>
              <Tab.Pane eventKey='first'>
                <FeatureTabPenLayout
                  imageBlock={<FeatureImages1 />}
                  contentBlock={<FeatureWidgetsBlock />}
                  contentBlockTitle={featureSectionData.featureTabTitle1}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='second'>
                <FeatureTabPenLayout
                  imageBlock={<FeatureImages2 />}
                  contentBlock={<FeatureWidgetsBlock2 />}
                  contentBlockTitle={featureSectionData.featureTabTitle2}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='third'>
                <FeatureTabPenLayout
                  imageBlock={<FeatureImages3 />}
                  contentBlock={<FeatureWidgetsBlock3 />}
                  contentBlockTitle={featureSectionData.featureTabTitle3}
                />
              </Tab.Pane>
            </Tab.Content>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  )
}
