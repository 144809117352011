import { Images } from '~data'
export const generatingSystemInstallationData = t => {
  return {
    featureTabTitle1: `${t('offGrid', { ns: 'info' })}`,
    featureTabTitle2: `${t('onGrid', { ns: 'info' })}`,
    featureTabTitle3: `${t('hybrid', { ns: 'info' })}`,

    tabWidget1: [
      {
        // icon: Images.projectManagement.featureTwoIcon1,
        // icon: 'far fa-check-circle',
        // iconBackground: '#15cda8',
        //   title: 'Hemat uang no 1',
        text: `${t('offGridSystem', { ns: 'info' })}`,
      },
    ],
    tabWidget2: [
      {
        // icon: Images.projectManagement.featureTwoIcon1,
        // icon: 'far fa-check-circle',
        // iconBackground: '#15cda8',
        // //   title: 'Hemat uang no 1',
        text: `${t('onGridSystem', { ns: 'info' })}`,
      },
    ],
    tabWidget3: [
      {
        // icon: Images.projectManagement.featureTwoIcon1,
        // icon: 'far fa-check-circle',
        // iconBackground: '#15cda8',
        //   title: 'Hemat uang no 1',
        text: `${t('hybridSystem', { ns: 'info' })}`,
      },
    ],
  }
}
